<table mat-table [dataSource]="dataSource" class="full-width-table" matSort (matSortChange)="setSort($event)"
       [matSortActive]="sort.active" [matSortDirection]="sort.direction" matSortDisableClear>
    <!-- Icon Column -->
    <ng-container matColumnDef="icon">
        <th mat-header-cell *matHeaderCellDef class="col-icon" mat-sort-header>Ladepunkt</th>
        <td mat-cell *matCellDef="let element" class="col-icon">
            <div class="evse-icon-container">
                <mat-icon class="iblack" svgIcon="{{getHealthIcon(element.obj)}}"
                          [class]="element.obj.liveData.healthStatus != undefined? 'evse-icon' : ''"></mat-icon>
            </div>
        </td>
        <td mat-footer-cell *matFooterCellDef class="col-icon"></td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="evseName">
        <th mat-header-cell *matHeaderCellDef class="col-name"></th>
        <td mat-cell *matCellDef="let element" class="col-name">
            <app-evse-link [evse]="element.obj"></app-evse-link>
        </td>
        <td mat-footer-cell *matFooterCellDef class="col-name"><b>Summe</b></td>
    </ng-container>
    <ng-container matColumnDef="fleetName">
        <th mat-header-cell *matHeaderCellDef class="col-name" mat-sort-header>Flotte</th>
        <td mat-cell *matCellDef="let element">
            {{ element.obj.name }}
        </td>
        <td mat-footer-cell *matFooterCellDef>Summe</td>
    </ng-container>

    <!-- Name Number Of Evses -->
    <ng-container matColumnDef="numberOfEvses">
        <th mat-header-cell *matHeaderCellDef class="col-name" mat-sort-header>Anzahl der Ladepunkte</th>
        <td mat-cell *matCellDef="let element">
            {{ element.numberOfEvses }}
        </td>
        <td mat-footer-cell *matFooterCellDef>
            <b>{{ footerRow.numberOfEvses }}</b>
        </td>
    </ng-container>

    <!-- Name Number Of Faults -->
    <ng-container matColumnDef="numberOfFaults">
        <th mat-header-cell *matHeaderCellDef class="col-name" mat-sort-header>
            <span
                matTooltip="Die Anzahl an ausfällen als Zahl. Gewertet werden alle Ausfälle die in der Ladepunkt übersicht zu einer Störung geführt haben. Die Vorfälle die zu einer Warnung geführt haben, werden nicht gewertet. Es können evtl Ausfälle mit gezählt werden die durch eine Neuverbinden des Ladepunktes entstanden sind, durch welchen allerdings keine Probleme beim Laden verursacht wurden.">
                Anzahl der Fehler
            </span>
        </th>
        <td mat-cell *matCellDef="let element">
            {{ element.numberOfFaults != 0 ? element.numberOfFaults : '---' }}
        </td>
        <td mat-footer-cell *matFooterCellDef>
            {{ footerRow.numberOfFaults != 0 ? footerRow.numberOfFaults : '---' }}
        </td>
    </ng-container>

    <!-- Name Duration Of Faults -->
    <ng-container matColumnDef="durationOfFault">
        <th mat-header-cell *matHeaderCellDef class="col-name" mat-sort-header>
            <span
                matTooltip='In der Prozentuallen Auswertung der Ausfall Dauer werden Zeiten berechnet in dem sich der Ladepunkt im Modus "Störung" oder "Störung (in Arbeit)" befunden hat. Allerdings nicht die Zeiten in dem der Ladepunkt sich im Wartungsmodus befunden hat'>
                 Ausfallzeit
            </span>
        </th>
        <td mat-cell *matCellDef="let element">
            {{ element.durationOfFaultsHumanReadable }}
        </td>
        <td mat-footer-cell *matFooterCellDef>
            {{ footerRow.durationOfFaultsHumanReadable }}
        </td>
    </ng-container>


    <!-- Name percentage Of availability -->
    <ng-container matColumnDef="percentageOfAvailability">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <div class="d-flex flex-column">
                <div>
                    Verfügbarkeit
                </div>
            </div>


        </th>
        <td mat-cell *matCellDef="let element">
            <div class="d-flex align-items-center pie-chart-container">
                <div class="d-flex align-items-center">
                    <span>
                        {{ percentageAsString(element.percentage.broken, 0, false) }}
                    </span>
                </div>
            </div>
        </td>
        <td mat-footer-cell *matFooterCellDef>
            <div class="d-flex align-items-center pie-chart-container">
                <div class="d-flex align-items-center">
                    <span>
                        {{ percentageAsString(footerRow.percentage.broken, 0, false) }}
                </span>
                </div>
            </div>
        </td>
    </ng-container>

    <!-- Name percentage Of availability -->
    <ng-container matColumnDef="pieChart">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <div class="d-flex flex-column">
            </div>


        </th>
        <td mat-cell *matCellDef="let element">
            <div cdk-overlay-origin #trigger="cdkOverlayOrigin" (mouseenter)="isOpen = element.obj"
                 (mouseleave)="isOpen = undefined">
                <div class="pie-chart" [style]="element.pieChartStyle" *ngIf="isAvailable(element)"></div>
            </div>
            <ng-template cdk-connected-overlay
                         [cdkConnectedOverlayOpen]="isOpen == element.obj"
                         [cdkConnectedOverlayOrigin]="trigger"
                         [cdkConnectedOverlayPositions]="[
                                    {
                                        originX: 'center',
                                        originY: 'bottom',
                                        overlayX: 'center',
                                        overlayY: 'top'
                                    }
                                 ]"
            >
                <div class="megatooltip">
                    <div class="legends">
                        <div class="legend">
                            <div class="legend-box broken"></div>
                            <span
                                class="legend-text">Störung {{ percentageAsString(element.percentage.broken, 2, true) }}</span>
                        </div>
                    </div>
                    <div class="legends">
                        <div class="legend">
                            <div class="legend-box working"></div>
                            <span
                                class="legend-text">Online {{ percentageAsString(element.percentage.working, 2, true) }}</span>
                        </div>
                    </div>
                    <div class="legends">
                        <div class="legend">
                            <div class="legend-box maintenance"></div>
                            <span
                                class="legend-text">Wartung {{ percentageAsString(element.percentage.maintenance, 2, true) }}</span>
                        </div>
                    </div>
                </div>
            </ng-template>
        </td>
        <td mat-footer-cell *matFooterCellDef>
            <div cdk-overlay-origin #triggerFooter="cdkOverlayOrigin" (mouseenter)="isOpen = -1"
                 (mouseleave)="isOpen = undefined">
                <div class="pie-chart" [style]="footerRow.pieChartStyle" *ngIf="isAvailable(footerRow)"></div>
            </div>
            <ng-template cdk-connected-overlay
                         [cdkConnectedOverlayOpen]="isOpen == -1"
                         [cdkConnectedOverlayOrigin]="triggerFooter"
                         [cdkConnectedOverlayPositions]="[
                                    {
                                        originX: 'center',
                                        originY: 'bottom',
                                        overlayX: 'center',
                                        overlayY: 'top'
                                    }
                                 ]"
            >
                <div class="megatooltip">
                    <div class="legends">
                        <div class="legend">
                            <div class="legend-box broken"></div>
                            <span
                                class="legend-text">Störung {{ percentageAsString(footerRow.percentage.broken, 2, true) }}</span>
                        </div>
                    </div>
                    <div class="legends">
                        <div class="legend">
                            <div class="legend-box working"></div>
                            <span
                                class="legend-text">Online {{ percentageAsString(footerRow.percentage.working, 2, true) }}</span>
                        </div>
                    </div>
                    <div class="legends">
                        <div class="legend">
                            <div class="legend-box maintenance"></div>
                            <span
                                class="legend-text">Wartung {{ percentageAsString(footerRow.percentage.maintenance, 2, true) }}</span>
                        </div>
                    </div>
                </div>
            </ng-template>
        </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="getDisplayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: getDisplayedColumns;"
        (click)="$event.stopPropagation();"
        [ngClass]="{selected: selectedIndex === row.id}">
    </tr>
    <tr mat-footer-row *matFooterRowDef="getDisplayedColumns" class="footer-row"></tr>
</table>
<mat-paginator #paginator [length]="dataSource?.data.length" [pageIndex]="pageIndex" [pageSize]="pageSize"
               [pageSizeOptions]="pageSizeOptions" showFirstLastButtons>
</mat-paginator>
