import { Component, Input, OnInit } from '@angular/core';
import {ExecuteMeterAddOrUpdate, SystemInfoMeterType} from '@io-elon-common/frontend-api';
import { IEditForm } from '../../../../shared/components/dialogs/edit-dialog/edit-dialog.component';
import {FormBuilder} from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import {SystemService} from "../../../../services/api-handlers/system.service";
interface StateGroupManufacturer {
    //For Autocomplete
    manufacturer: string;
    meters: Array<SystemInfoMeterType>
}

@Component({
    selector: 'app-edit-meter-dialog',
    templateUrl: './edit-meter-dialog.component.html',
    styleUrls: ['./edit-meter-dialog.component.scss']
})
export class EditMeterDialogComponent implements IEditForm<ExecuteMeterAddOrUpdate>, OnInit {

    @Input()
    public data!: ExecuteMeterAddOrUpdate;

    public types!: Array<SystemInfoMeterType>;
    public meterType!: SystemInfoMeterType;

    public meterList: StateGroupManufacturer[] = [];
    public stateForm = this.formBuilder.group({
        stateGroup: "",
    });
    public manufacturerGroupOptions: Observable<StateGroupManufacturer[]> | undefined;


    public constructor(
        private readonly systemService: SystemService,
        private readonly formBuilder: FormBuilder
    ) {
    }

    get interval(): number {
        return this.data.interval / 1000;
    }

    set interval(val: number) {
        this.data.interval = val * 1000;
    }

    public async ngOnInit(): Promise<void> {
        this.types = (await this.systemService.getSystemInfo()).supportedMeters;
        this.meterType = this.types.filter(t => t.key === this.data.type)[0];
        this.stateForm = this.formBuilder.group({
            stateGroup: this.data ? this.data.type : "",
        });

        this.types.sort((a, b) => a.manufacturer.localeCompare(b.manufacturer));

        const uniqueManufacturer = Array.from(
            new Set(
                this.types.map((meter) => {
                    return meter.manufacturer;
                })
            )
        );

        if (this.data.type) {
            const meterName = this.types.find(t => t.key === this.data.type)?.name ?? "";
            this.stateForm.setValue({"stateGroup": meterName})
        }

        for (const manufacturer of uniqueManufacturer) {
            this.meterList.push({
                manufacturer: manufacturer,
                meters: this.types.filter(vObj => vObj.manufacturer === manufacturer).sort((a, b) => a.name.localeCompare(b.name))
            });
        }

        this.manufacturerGroupOptions = this.stateForm
            .get("stateGroup")!
            .valueChanges.pipe(
                startWith(""),
                map((value) => this.filterGroup(value || ""))
            );
    }

    private filterGroup(value: string): StateGroupManufacturer[] {
        if (value) {
            this.meterType = this.types.filter(t => t.name === value)?.[0]
            this.data.type = this.meterType?.key
            return this.meterList.map(group => ({
                manufacturer: group.manufacturer,
                meters: group.meters.filter(e => e.name.toLowerCase().includes(value.toLowerCase()) || e.manufacturer.toLocaleLowerCase().includes(value.toLocaleLowerCase()))
            })).filter((group) => group.meters.length > 0);
        }
        return this.meterList;
    }

    public validate(): string[] {
        const val = [];
        if (this.data.name === undefined || this.data.name === "") {
            val.push("Der Zählername wird nicht angegeben<br/>");
        }
        if (this.data.type === undefined || this.data.type === "") {
            val.push("Der Zählertyp ist nicht angegeben<br/>");
        }
        if (this.meterType !== undefined && this.meterType.type === "Modbus" && (this.data.modbusId === undefined || this.data.modbusId === null)) {
            val.push("Modbus Id ist nicht angegeben");
        }
        return val;
    }
}
